<template>
    <div>
        <div class="mb-2">
            <label class="section-label">Date of Investigation</label>
            <div :class="{ 'error--text': !date }">{{date || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">What type of work was carried out in relation to the Incident?</label>
            <div :class="{ 'error--text': !typeOfWork }">{{typeOfWork || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Was the customer present during those works?</label>
            <div>{{toYesNo(customerPresent)}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Did the customer sign paperwork saying they were happy with the works?</label>
            <div>{{toYesNo(customerSignPaperwork)}}</div>
        </div>

        <v-row class="mt-2 mx-0" v-if="customerSignPaperwork">
            <v-alert type="info" dense>
                Can you provide a copy of the paperwork.
            </v-alert>
        </v-row>

        <div class="mb-2">
            <label class="section-label">Were the works attended by employees of Virgin Media or a Contract Partner</label>
            <div :class="{ 'error--text': !whoWorksAttendedByName }">{{whoWorksAttendedByName || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Enter VM Employee or Contract Partner Technician Number and/or the name of the VM Employee or Contract Partner (and any relevant contact details)</label>
            <div :class="{ 'error--text': !contactDetailsofWhoAttendedBy }">{{contactDetailsofWhoAttendedBy || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Please give any details on the alleged hazard/damage, such as measurements etc. (Take Video / Image)</label>
            <div :class="{ 'error--text': !detailsOfHazardDamage }">{{detailsOfHazardDamage || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Is there anything else that could have caused the hazard? (Take Video / Image of why you think this)</label>
            <div :class="{ 'error--text': !otherCauseHazard }">{{otherCauseHazard || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Is the alleged hazard still present?</label>
            <div>{{toYesNo(hazardPresent)}}</div>
        </div>

        <div class="mb-2" v-if="hazardPresent">
            <label class="section-label">Describe what works are required and who you have contacted to organise this</label>
            <div :class="{ 'error--text': !yesHazardPresent }">{{yesHazardPresent || 'Not specified'}}</div>
        </div>

        <div class="mb-2" v-if="!hazardPresent">
            <label class="section-label">If no, do you have any details of who repaired this?</label>
            <div :class="{ 'error--text': !noHazardPresent }">{{noHazardPresent || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">If the claim is in relation to a lightning strike or power surge, were isolators fitted?</label>
            <div :class="{ 'error--text': !lightningStrikeName }">{{lightningStrikeName || 'Not specified'}}</div>
        </div>

        <v-row class="mt-2 mx-0" v-if="lightningStrikeId==600010051">
            <v-alert type="info" dense>
                Can you please ensure these are fitted.
            </v-alert>
        </v-row>

        <div class="mb-2">
            <label class="section-label">Does it look like any recent works by a third party may have been in the vicinity, that could have caused this hazard/damage?</label>
            <div>{{toYesNo(thirdPartyWorks)}}</div>
        </div>

        <v-row class="mt-2 mx-0" v-if="thirdPartyWorks">
            <v-alert type="info" dense>
                Take Video / Image of this.
            </v-alert>
        </v-row>

        <div class="mb-2" v-if="thirdPartyWorks">
            <label class="section-label">Please explain</label>
            <div :class="{ 'error--text': !thirdPartyWorksDetails }">{{thirdPartyWorksDetails || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Has the claimant been in contact with anyone else regarding this hazard/damage?</label>
            <div>{{toYesNo(hazardContactWithAnyone)}}</div>
        </div>

        <div class="mb-2" v-if="hazardContactWithAnyone">
            <label class="section-label">Please explain</label>
            <div :class="{ 'error--text': !hazardContactWithAnyoneDetails }">{{hazardContactWithAnyoneDetails || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Do you have any observations based on the facts above which may help decide liability?</label>
            <div :class="{ 'error--text': !observations }">{{observations || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Do Virgin Media Have Any Records In Relation To The Alleged Incident? (Defect/ Section 81 records, Icoms, CCCS etc)</label>
            <div :class="{ 'error--text': !recordsExist }">{{recordsExist || 'Not specified'}}</div>
        </div>


    </div>
</template>

<script>
export default {
    props: {
        typeSystemName: String,
        date: String,                   // "DD/MM/YYYY"
        typeOfWork: String,
        customerPresent: Boolean,
        customerSignPaperwork: Boolean,
        whoWorksAttendedById: Number,
        whoWorksAttendedByName: String,
        contactDetailsofWhoAttendedBy: String,
        detailsOfHazardDamage: String,
        otherCauseHazard: String,
        hazardPresent: Boolean,
        yesHazardPresent: String,
        noHazardPresent: String,
        lightningStrikeId: Number,
        lightningStrikeName: String,
        thirdPartyWorks: Boolean,
        thirdPartyWorksDetails: String,
        hazardContactWithAnyone: Boolean,
        hazardContactWithAnyoneDetails: String,
        observations: String,
        recordsExist: String,
    },

    methods: {
        toYesNo: function(b) {
            return b ? "Yes" : "No";
        },
    },
};
</script>
