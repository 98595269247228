<template>
    <v-container>
        <VTopToolbar title="Task" :showBack="true" id="task-toolbar">
            <!-- Override right nav button -->
            <template slot="right">
                <v-icon v-if="canViewTime" @click="navTo('time-entries')">mdi-clock-outline</v-icon>
                 
                <v-btn icon @click="navTo('comments')">
                    <v-badge
                        :value="commentCount > 0"
                        :content="commentCount"
                        color="error lighten-1"
                        offset-x="12"
                        offset-y="14"
                    >
                        <v-icon>mdi-comment-outline</v-icon>
                    </v-badge>                    
                </v-btn>
                 
                 <v-icon :disabled="!participantActions || !phaseInfo" @click="showActionSheet()">
                     mdi-dots-horizontal
                </v-icon>
            </template>
        </VTopToolbar>

        <div v-show="loading">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </div>

        <div v-show="!loading">

            <div class="float-right" v-if="canEdit">
                <v-btn-section @click.stop="editTaskDetails()">
                    <v-icon>mdi-pencil-outline</v-icon>
                </v-btn-section>
            </div>

            <h3>
                {{ title }}
            </h3>

            <div class="mb-2 grey--text text--darken-2">
                <div>{{ `${(phaseInfo || {}).name} &ndash; ${reference || "[No reference]"}` }}</div>
            </div>

            <div class="mb-2" v-if="matterReference">
                <label class="section-label">Claim ref</label>
                <div>{{matterReference}}</div>
            </div>

            <div class="mb-2" v-if="matterReference">
                <label class="section-label">Instruction from</label>
                <div>{{matterOwner}}</div>
            </div>

            <div class="mb-2" v-if="createdDate">
                <label class="section-label">Instruction date</label>
                <div>{{$moment(createdDate).format('DD/MM/YYYY HH:mm')}}</div>
            </div>

            <div class="mb-2" v-if="assignedTo">
                <label class="section-label">Assigned to</label>
                <div>{{assignedTo}}</div>
            </div>

            <div class="mb-2" v-if="incidentDate">
                <label class="section-label">Incident date and time</label>
                <div>{{$moment(incidentDate).format('DD/MM/YYYY HH:mm')}}</div>
            </div>

            <div class="mb-2" v-if="locationText">
                <label class="section-label">Incident location</label>
                <div>{{locationText}}</div>
            </div>

            <div class="mb-2">
                <label class="section-label">Description</label>
                <div>{{incidentDescription || 'None'}}</div>
            </div>

            <v-expansion-panels flat tile class="mt-5 mb-5 grey-panels" v-model="openedPanel">

                <v-expansion-panel v-show="isResi">
                    <v-expansion-panel-header-editable
                        class="panel-header"
                        :canEdit="canEdit"
                        v-on:editClicked="editInvestigationResiDetails()"
                        :errorCount="investigationResiErrorCount"
                    >
                        Investigation
                    </v-expansion-panel-header-editable>
                    <v-expansion-panel-content>
                        <InvestigationResiDetails v-bind="investigationResi"></InvestigationResiDetails>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-show="isCivil">
                    <v-expansion-panel-header-editable
                        class="panel-header"
                        :canEdit="canEdit"
                        v-on:editClicked="editInvestigationCivilDetails()"
                        :errorCount="investigationCivilErrorCount"
                    >
                        Investigation
                    </v-expansion-panel-header-editable>
                    <v-expansion-panel-content>
                        <InvestigationCivilDetails v-bind="investigationCivil"></InvestigationCivilDetails>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="panel-header">{{(isResi || isCivil) ? "Photo / video evidence" : "Related documents"}}</v-expansion-panel-header>
                    <v-expansion-panel-content
                        id="documents-panel"
                        :eager=true
                    >
                        <v-alert type="info" dense>
                             Must have an image or be included in the video:
                             <ul class="mt-2">
                                 <li>Clear image of the hazard/damage</li>
                                 <li>A landscape of the wider area</li>
                                 <li>Any other relevant images.</li>
                             </ul>
                        </v-alert>

                        <related-documents
                            v-if="id"
                            :id="id"
                            :canEdit="canEdit"
                            :showLabel=false
                            uploadIcon="mdi-camera-plus-outline"
                            path="Investigation"
                            v-on:uploadAllowedChanged="onUploadAllowedChanged"
                        >
                            <template slot="placeholder">
                                <div>
                                    <div v-if="canEdit">Tap the <v-icon>mdi-camera-plus-outline</v-icon>&nbsp; icon to upload a photo/video.</div>
                                    <div v-if="!canEdit">No documents</div>
                                </div>
                            </template>
                        </related-documents>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-show="isResi || isCivil">
                    <v-expansion-panel-header-editable
                        class="panel-header"
                        :canEdit="canEdit"
                        v-on:editClicked="editWitnessList()"
                        :errorCount="witnessesErrorCount"
                    >
                        Potential witnesses
                    </v-expansion-panel-header-editable>
                    <v-expansion-panel-content>
                        <PotentialWitnesses v-bind="witnesses"></PotentialWitnesses>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                
            </v-expansion-panels>
            
            <v-btn
                block
                large
                color="primary"
                v-if="primaryAction"
                class="mt-6"
                @click="primaryAction.handler()"
                :disabled="!isValid"
            >
                <v-icon left>{{primaryAction.icon}}</v-icon>
                {{primaryAction.text}}
            </v-btn>            

            <v-btn
                color="#e10a0a"
                elevation="6"
                fab
                small
                dark
                id="fab-camera"
                v-if="id && cameraVisible"
                @click="cameraClicked()"
            >
                <v-icon>mdi-camera</v-icon>
            </v-btn>

        </div>
    </v-container>
</template>

<script>
import sharedoMenuService from "../../services/sharedo/sharedoMenuService.js";
import sharedoActionService from "../../services/sharedo/sharedoActionService.js";
import InvestigationResiDetails from "../PublicLiabilityInvestigationTasks/Sections/InvestigationResiDetails.vue";
import InvestigationCivilDetails from "../PublicLiabilityInvestigationTasks/Sections/InvestigationCivilDetails.vue";
import PotentialWitnesses from "../PublicLiabilityInvestigationTasks/Sections/PotentialWitnesses.vue";
import plTasksAgent from "../PublicLiabilityInvestigationTasks/plTasksAgent.js";
import tasksAgent from "./tasksAgent.js";
import { SharedoProfile } from "@sharedo/mobile-core";
import VBtnSection from "../../components/VBtnSection.vue";
import VExpansionPanelHeaderEditable from "../../components/VExpansionPanelHeaderEditable.vue";
import comments from "@/views/Comments/commentsAgent";

// Lazy load dialogs
const InvestigationResiDetailsForm = () => import("../PublicLiabilityInvestigationTasks/Forms/InvestigationResiDetailsForm.vue");
const InvestigationCivilDetailsForm = () => import("../PublicLiabilityInvestigationTasks/Forms/InvestigationCivilDetailsForm.vue");
const TaskDetailForm = () => import("./TaskDetailForm.vue");
const TaskDescriptionForm = () => import("./TaskDescriptionForm.vue");
const RelatedDocuments = () => import("../RelatedDocuments/RelatedDocuments.vue");
const WitnessListForm = () => import("../PublicLiabilityInvestigationTasks/Forms/WitnessListForm");

export default {
    components: {
        InvestigationResiDetails,
        InvestigationCivilDetails,
        PotentialWitnesses,
        TaskDetailForm,
        TaskDescriptionForm,
        RelatedDocuments,
        VBtnSection,
        VExpansionPanelHeaderEditable,
    },

    data: function () {
        return {
            loading: true,
            openedPanel: null,
            id: null,
            reference: null,
            referenceIsUserProvided: false,
            title: null,
            titleIsUserProvided: false,
            typeSystemName: null,
            description: null,
            dueDate: null,
            phaseName: null,
            assignedTo: null,
            canEdit: false,
            canProgress: false,
            participantActions: null,
            phaseInfo: null,
            takeOwnershipBanner: null,
            canViewTime: false,
            primaryAction: null,
            commentCount: 0,
            cameraVisible: false,
            matterReference: "",
            matterOwner: "",
            createdDate: "",
            locationText: "",
            incidentDescription: "",
            incidentDate: "",

            investigationResi: {
                date: null,
                typeOfWork: null,
                customerPresent: false,
                customerSignPaperwork: false,
                whoWorksAttendedById: null,
                whoWorksAttendedByName: null,
                contactDetailsofWhoAttendedBy: null,
                detailsOfHazardDamage: null,
                otherCauseHazard: null,
                hazardPresent: false,
                yesHazardPresent: null,
                noHazardPresent: null,
                lightningStrikeId: null,
                lightningStrikeName: null,
                thirdPartyWorks: false,
                thirdPartyWorksDetails: null,
                hazardContactWithAnyone: false,
                hazardContactWithAnyoneDetails: null,
                observations: null,
                recordsExist: null,
            },

            investigationCivil: {
                date: null,
                thirdPartyRecentWorks: null,
                apparatusVM: null,
                apparatusVMDetails: null,
                whenInstalled: null,
                lastAttended: null,
                defective: null,
                defectiveDetails: null,
                defectPresent: null,
                hazardsVicinity: null,
                hazardsVicinityDetails: null,
                evidenceVMWork: null,
                evidenceVMWorkDetails: null,
                contractor: null,
                observations: null,
            },

            witnesses: {
                witnessList: null,
            },
        };
    },

    computed: {
        isMine: function () {
            return this.primaryOwnerId === SharedoProfile.profile.userId;
        },
        
        isResi: function() {
            return this.typeSystemName === "vm-pl-investigation-resi";
        },
        
        isCivil: function() {
            return this.typeSystemName === "pl-investigation-civil";
        },
        
        isValid: function() {
            var i = 0;
            if (this.isResi) i += this.investigationResiErrorCount;
            if (this.isCivil) i += this.investigationCivilErrorCount;
            i += this.witnessesErrorCount;
            return i === 0;
        },

        investigationResiErrorCount: function() {
            var i = 0;
            if (!this.isResi) return 0;

            if (!this.investigationResi.date) i++;
            if (!this.investigationResi.typeOfWork) i++;
            if (!this.investigationResi.whoWorksAttendedById || !this.investigationResi.whoWorksAttendedByName) i++;
            if (!this.investigationResi.contactDetailsofWhoAttendedBy) i++;
            if (!this.investigationResi.detailsOfHazardDamage) i++;
            if (!this.investigationResi.otherCauseHazard) i++;
            if (this.investigationResi.hazardPresent && !this.investigationResi.yesHazardPresent) i++;
            if (!this.investigationResi.hazardPresent && !this.investigationResi.noHazardPresent) i++;
            if (!this.investigationResi.lightningStrikeId || !this.investigationResi.lightningStrikeName) i++;
            if (this.investigationResi.thirdPartyWorks && !this.investigationResi.thirdPartyWorksDetails) i++;
            if (this.investigationResi.hazardContactWithAnyone && !this.investigationResi.hazardContactWithAnyoneDetails) i++;
            if (!this.investigationResi.observations) i++;
            if (!this.investigationResi.recordsExist) i++;
            return i;
        },

        investigationCivilErrorCount: function() {
            var i = 0;
            if (!this.isCivil) return 0;

            if (!this.investigationCivil.date) i++;
            if (!this.investigationCivil.apparatusVM && !this.investigationCivil.apparatusVMDetails) i++;

            if (this.investigationCivil.apparatusVM) {
                if (!this.investigationCivil.whenInstalled) i++;
                if (!this.investigationCivil.lastAttended) i++;
                if (this.investigationCivil.defective && !this.investigationCivil.defectiveDetails) i++;
                if (this.investigationCivil.hazardsVicinity && !this.investigationCivil.hazardsVicinityDetails) i++;
                if (this.investigationCivil.evidenceVMWork && !this.investigationCivil.evidenceVMWorkDetails) i++;
                if (this.investigationCivil.evidenceVMWork && !this.investigationCivil.contractor) i++;
                if (!this.investigationCivil.observations) i++;
            }
            return i;
        },

        witnessesErrorCount: function() {
            var i = 0;
            if (!this.witnesses.witnessList || this.witnesses.witnessList === "<p></p>") i++;
            return i;
        },

    },

    mounted: function () {
        this.id = this.$route.params.id;

        // Time not required for PL investigation tasks
        //this.canViewTime = SharedoProfile.profile.globalPermissions.indexOf("core.time.read") !== -1;

        this.load();
    },

    methods: {
        async load() {
            await Promise.all([this.loadTask(), this.loadCommentCount()]);
        },
        async loadCommentCount() {
            try {
                const response = await comments.getComments(this.id, 1, 1);
                this.commentCount = response.totalRows;
            } catch (error) {
                console.error(error);
            }
        },
        loadTask () {
            this.loading = true;
            this.takeOwnershipBanner && this.takeOwnershipBanner.close();

            // TODO replace getTask with this
            plTasksAgent.getPlTaskEnriched(this.id).then(response => {
                var item = response.results[0];
                if (!item) throw "Sharedo not found: " + this.id;

                var data = item.data;

                this.reference = data.reference;
                this.title = data.title;
                this.description = data["description.html"];
                this.typeSystemName = data["type.systemName"];
                this.matterReference = data["parent!q?path=matter.reference"];
                this.matterOwner = data["parent!q?path=matter.roles.matter-owner.ods.name"];
                this.createdDate = data["createdDate.utc.value"];
                this.locationText = data["parent!q?type=matter-dispute-defendant-pd.incidentDetailsLocation.location.formatted"];
                this.incidentDescription = data["description.text"];
                this.incidentDate = data["parent!q?type=matter-dispute-defendant-pd.keyDates.kd-incident-date.taskDueDate.date.utc.value"];
                
                // Investigation Resi details
                this.investigationResi.date = data["form-vm-pl-investigation-residential-part2.dateInvestigation"];
                this.investigationResi.typeOfWork = data["form-vm-pl-investigation-residential-part2.memoTypeOfWork"];
                this.investigationResi.customerPresent = data["form-vm-pl-investigation-residential-part2.toggleCustomerPresent"];
                this.investigationResi.customerSignPaperwork = data["form-vm-pl-investigation-residential-part2.toggleCustomerSignPaperwork"];
                this.investigationResi.whoWorksAttendedById = data["form-vm-pl-investigation-residential-part2.optionWhoWorksAttendedBy.id"];
                this.investigationResi.whoWorksAttendedByName = data["form-vm-pl-investigation-residential-part2.optionWhoWorksAttendedBy.name"];
                this.investigationResi.contactDetailsofWhoAttendedBy = data["form-vm-pl-investigation-residential-part2.memoContactDetailsOfWhoAttendedBy"];
                this.investigationResi.detailsOfHazardDamage = data["form-vm-pl-investigation-residential-part2.memoDetailsOfHazardDamage"];
                this.investigationResi.otherCauseHazard = data["form-vm-pl-investigation-residential-part2.memoOtherCauseHazard"];
                this.investigationResi.hazardPresent = data["form-vm-pl-investigation-residential-part2.toggleHazardPresent"];
                this.investigationResi.yesHazardPresent = data["form-vm-pl-investigation-residential-part2.memoYesHazardPresent"];
                this.investigationResi.noHazardPresent = data["form-vm-pl-investigation-residential-part2.memoNoHazardPresent"];
                this.investigationResi.lightningStrikeId = data["form-vm-pl-investigation-residential-part2.optionSetLightning.id"];
                this.investigationResi.lightningStrikeName = data["form-vm-pl-investigation-residential-part2.optionSetLightning.name"];
                this.investigationResi.thirdPartyWorks = data["form-vm-pl-investigation-residential-part2.toggleThirdPartyWorks"];
                this.investigationResi.thirdPartyWorksDetails = data["form-vm-pl-investigation-residential-part2.memoThirdPartyWorks"];
                this.investigationResi.hazardContactWithAnyone = data["form-vm-pl-investigation-residential-part2.toggleHazardContactWithAnyone"];
                this.investigationResi.hazardContactWithAnyoneDetails = data["form-vm-pl-investigation-residential-part2.memoHazardContactWithAnyone"];
                this.investigationResi.observations = data["form-vm-pl-investigation-residential-part2.memoObservations"];
                this.investigationResi.recordsExist = data["form-vm-pl-investigation-residential-part2.memoRecordsExist"];

                // Investigation Civil details
                this.investigationCivil.date = data["form-vm-pl-investigation-civil.dateInvestigation"];
                this.investigationCivil.thirdPartyRecentWorks = data["form-vm-pl-investigation-civil.toggleThirdPartyRecentWorks"];
                this.investigationCivil.apparatusVM = data["form-vm-pl-investigation-civil.toggleApparatusVM"];
                this.investigationCivil.apparatusVMDetails = data["form-vm-pl-investigation-civil.memoApparatusVM"];
                this.investigationCivil.whenInstalled = data["form-vm-pl-investigation-civil.memoWhenInstalled"];
                this.investigationCivil.lastAttended = data["form-vm-pl-investigation-civil.memoLastAttended"];
                this.investigationCivil.defective = data["form-vm-pl-investigation-civil.toggleDefective"];
                this.investigationCivil.defectiveDetails = data["form-vm-pl-investigation-civil.memoDefective"];
                this.investigationCivil.defectPresent = data["form-vm-pl-investigation-civil.toggleDefectPresent"];
                this.investigationCivil.hazardsVicinity = data["form-vm-pl-investigation-civil.toggleHazardsVicinity"];
                this.investigationCivil.hazardsVicinityDetails = data["form-vm-pl-investigation-civil.memoHazardsVicinity"];
                this.investigationCivil.evidenceVMWork = data["form-vm-pl-investigation-civil.toggleEvidenceVMWork"];
                this.investigationCivil.evidenceVMWorkDetails = data["form-vm-pl-investigation-civil.memoEvidenceVMWork"];
                this.investigationCivil.contractor = data["form-vm-pl-investigation-civil.memoContractor"];
                this.investigationCivil.observations = data["form-vm-pl-investigation-civil.memoObservations"];

                // Potential witnesses
                this.witnesses.witnessList = data["form-vm-pl-investigation-witness.memoWitnessList.html"];
            }).catch(console.error);

            tasksAgent.getTask(this.id).then((task) => {
                this.referenceIsUserProvided = task.workItem.referenceIsUserProvided;
                this.titleIsUserProvided = task.workItem.titleIsUserProvided;
                this.dueDate = task.aspectData.taskDueDate && task.aspectData.taskDueDate.dueDateTime;
                this.assignedTo = task.aspectData.taskAssignedTo && task.aspectData.taskAssignedTo.displayName;

                this.loading = false;
            }).catch(console.error);

            tasksAgent.getPermissions(this.id).then(perm => {
                this.permissions = perm;

                // TODO is closed?
                this.canEdit = perm.includes("core.sharedo.update");
                this.canProgress = perm.includes("core.sharedo.progress.milestone");

            }).catch(console.error);
                            
            tasksAgent.getPhaseInfo(this.id).then(info => {
                this.phaseInfo = info;

                var transOpts = {
                    sharedoId: this.id,
                    callback: this.afterTransitionTo.bind(this),
                };

                // Get primary action
                this.primaryAction = this.phaseInfo.availableTransitions
                    .filter(t => t.isUserDriven && t.isOptimumPath)
                    .map(t => {
                        var transTo = Object.assign({}, t, transOpts);

                        return {
                            text: t.name,
                            icon: t.toPhaseIconClass || "mdi-fast-forward-outline",
                            handler: sharedoActionService.confirmTransitionTo.bind(this, transTo),
                        };
                    })[0];

            }).catch(console.error);

            tasksAgent.getTaskActions(this.id).then(actions => {
                this.participantActions = actions;

                if (!this.canEdit && this.participantActions.canTakeOwnership) {
                    var takeOpts = {
                        id: this.id,
                        callback: this.afterTakeOwnership,
                    };

                    // Locked - show banner
                    this.takeOwnershipBanner = this.$coreUi.banner({
                        message: "You cannot edit this item.",
                        btns: [
                            { text: "Become owner", color: "primary", handler: sharedoActionService.takeOwnership.bind(this, takeOpts) }
                        ],
                    });
                }
            }).catch(console.error);
        },

        showActionSheet () {
            var items = sharedoMenuService.getSharedoActionsMenu({
                id: this.id,
                includeChronologyLink: false,       // Not required for PL investigation tasks
                includeParticipantsLink: false,     // Not required for PL investigation tasks
                rolePermissions: this.permissions,
                phaseInfo: this.phaseInfo,
                participantActions: this.participantActions,
                afterTakeOwnership: this.afterTakeOwnership.bind(this),
                afterTransitionTo: this.afterTransitionTo.bind(this),
            });

            this.$coreUi.actionSheet({
                items: items
            });
        },

        editTaskDetails () {
            this.$coreUi.dialog(TaskDetailForm,
                {
                    id: this.id,
                    reference: this.reference,
                    referenceIsUserProvided: this.referenceIsUserProvided,
                    title: this.title,
                    titleIsUserProvided: this.titleIsUserProvided,
                    dueDate: this.dueDate,
                },
                {
                    closing: (result) => {
                        if (result) this.loadTask();
                    }
                });
        },

        editTaskDescription () {
            this.$coreUi.dialog(TaskDescriptionForm,
                {
                    id: this.id,
                    description: this.description,
                },
                {
                    closing: (result) => {
                        if (result) this.loadTask();
                    }
                });
        },

        editInvestigationResiDetails () {
            var model = Object.assign({ id: this.id }, this.investigationResi);

            this.$coreUi.dialog(InvestigationResiDetailsForm,
                model,
                {
                    closing: (result) => {
                        if (result) this.loadTask();
                    }
                });
        },

        editInvestigationCivilDetails () {
            var model = Object.assign({ id: this.id }, this.investigationCivil);

            this.$coreUi.dialog(InvestigationCivilDetailsForm,
                model,
                {
                    closing: (result) => {
                        if (result) this.loadTask();
                    }
                });
        },

        editWitnessList () {
            var model = Object.assign({ id: this.id }, this.witnesses);

            this.$coreUi.dialog(WitnessListForm,
                model,
                {
                    closing: (result) => {
                        if (result) this.loadTask();
                    }
                });
        },

        afterTakeOwnership () {
            this.takeOwnershipBanner && this.takeOwnershipBanner.close();
            
            // Reload permissions/actions
            this.loadTask();
        },

        afterTransitionTo (opts) {
            if (opts && opts.shouldCloseUi)
                this.$router.back();
            else
                this.loadTask();
        },

        navTo (subPage) {
            this.$router.push({ path: `${window.location.pathname}/${subPage}` });
        },

        onUploadAllowedChanged(state) {
            this.cameraVisible = state;
        },

        cameraClicked() {
            this.openedPanel = 2;   // Related docs

            setTimeout(() => {
                let element = document.getElementById("input-upload");
                if (element) {
                    element.click();
                }
            }, 200);
        },
    },
};
</script>

<style>
#task-toolbar .spacer ~ .v-card__actions {
    margin-left: -25px; /* Attempt to centre title - better way? */
}

/* Expansion panels - move action button into header */
#documents-panel div.header-btns {
    position: absolute;
    top: 10px;
    right: 45px;
}

</style>