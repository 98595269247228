<template>
    <div>
        <div class="mb-2">
            <label class="section-label">Can you list the names of anyone that may be beneficial as a witness to Virgin?</label>
            <div 
                :class="{ 'error--text': !witnessList || witnessList === '<p></p>' }"
                v-html="witnessList || 'Not specified'"></div>
        </div>

        <div class="mt-2 mx-0">
            <v-alert type="info" dense>
                These statements may be used in court at a later date if necessary.
            </v-alert>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        typeSystemName: String,
        witnessList: String,
    },
};
</script>
