import { SharedoFetch } from '@sharedo/mobile-core'

function getPlTaskEnriched(id)
{
    if( !id ) throw "getPlTaskEnriched: no id";

    return SharedoFetch.post("/api/v1/public/workItem/findByQuery",
    {
        search:
        {
            page: { rowsPerPage: 1, page: 1 },
            workItemIds: [ id ],
        },
        enrich:
        [
            { path: "reference" },
            { path: "title" },
            { path: "description.html" },
            { path: "type.systemName" },
            { path: "type.name" },
            { path: "type.iconClass" },
            { path: "phase.name" },
            { path: "parent!q?path=matter.reference" },
            { path: "parent!q?path=matter.roles.matter-owner.ods.name" },
            { path: "createdDate.utc.value"},
            { path: "parent!q?type=matter-dispute-defendant-pd.incidentDetailsLocation.location.formatted" },
            { path: "description.text" },
            { path: "parent!q?type=matter-dispute-defendant-pd.keyDates.kd-incident-date.taskDueDate.date.utc.value" },
            
            // Investigation Resi details
            { path: "form-vm-pl-investigation-residential-part2.dateInvestigation" },
            { path: "form-vm-pl-investigation-residential-part2.memoTypeOfWork" },
            { path: "form-vm-pl-investigation-residential-part2.toggleCustomerPresent" },
            { path: "form-vm-pl-investigation-residential-part2.toggleCustomerSignPaperwork" },
            { path: "form-vm-pl-investigation-residential-part2.optionWhoWorksAttendedBy.id" },
            { path: "form-vm-pl-investigation-residential-part2.optionWhoWorksAttendedBy.name" },
            { path: "form-vm-pl-investigation-residential-part2.memoContactDetailsOfWhoAttendedBy" },
            { path: "form-vm-pl-investigation-residential-part2.memoDetailsOfHazardDamage" },
            { path: "form-vm-pl-investigation-residential-part2.memoOtherCauseHazard" },
            { path: "form-vm-pl-investigation-residential-part2.toggleHazardPresent" },
            { path: "form-vm-pl-investigation-residential-part2.memoYesHazardPresent" },
            { path: "form-vm-pl-investigation-residential-part2.memoNoHazardPresent" },
            { path: "form-vm-pl-investigation-residential-part2.optionSetLightning.id" },
            { path: "form-vm-pl-investigation-residential-part2.optionSetLightning.name" },
            { path: "form-vm-pl-investigation-residential-part2.toggleThirdPartyWorks" },
            { path: "form-vm-pl-investigation-residential-part2.memoThirdPartyWorks" },
            { path: "form-vm-pl-investigation-residential-part2.toggleHazardContactWithAnyone" },
            { path: "form-vm-pl-investigation-residential-part2.memoHazardContactWithAnyone" },
            { path: "form-vm-pl-investigation-residential-part2.memoObservations" },
            { path: "form-vm-pl-investigation-residential-part2.memoRecordsExist" },

            // Investigation Civil details
            { path: "form-vm-pl-investigation-civil.dateInvestigation" },
            { path: "form-vm-pl-investigation-civil.toggleThirdPartyRecentWorks" },
            { path: "form-vm-pl-investigation-civil.toggleApparatusVM" },
            { path: "form-vm-pl-investigation-civil.memoApparatusVM" },
            { path: "form-vm-pl-investigation-civil.memoWhenInstalled" },
            { path: "form-vm-pl-investigation-civil.memoLastAttended" },
            { path: "form-vm-pl-investigation-civil.toggleDefective" },
            { path: "form-vm-pl-investigation-civil.memoDefective" },
            { path: "form-vm-pl-investigation-civil.toggleDefectPresent" },
            { path: "form-vm-pl-investigation-civil.toggleHazardsVicinity" },
            { path: "form-vm-pl-investigation-civil.memoHazardsVicinity" },
            { path: "form-vm-pl-investigation-civil.memoObservations" },
            { path: "form-vm-pl-investigation-civil.memoContractor" },
            { path: "form-vm-pl-investigation-civil.toggleEvidenceVMWork" },
            { path: "form-vm-pl-investigation-civil.memoEvidenceVMWork" },
            
            // Potential witnesses
            { path: "form-vm-pl-investigation-witness.memoWitnessList.html" },
        ]
    });    
}

export default 
{
    getPlTaskEnriched,
};
