<template>
    <div>
        <div class="mb-2">
            <label class="section-label">Date of Investigation</label>
            <div :class="{ 'error--text': !date }">{{date || 'Not specified'}}</div>
        </div>

        <div class="mb-2">
            <label class="section-label">Reviewing the allegations and/or photos provided, does the apparatus belong to Virgin Media?</label>
            <div>{{toYesNo(apparatusVM)}}</div>
        </div>

        <div class="mb-2" v-if="!apparatusVM">
            <label class="section-label">Please detail why you think this apparatus is not VM's and whose you think it might be</label>
            <div :class="{ 'error--text': !apparatusVMDetails }">{{apparatusVMDetails || 'Not specified'}}</div>
        </div>

        <v-row class="mt-2 mx-0" v-if="!apparatusVM">
            <v-alert type="info" dense>
                Take Video / Image of why you think this is not VM and whose apparatus you think this might be.
            </v-alert>
        </v-row>

        <div class="mb-2" v-if="apparatusVM">
            <label class="section-label">When was the apparatus installed? Was it over 2 years ago?</label>
            <div :class="{ 'error--text': !whenInstalled }">{{whenInstalled || 'Not specified'}}</div>
        </div>

        <div class="mb-2" v-if="apparatusVM">
            <label class="section-label">When was the apparatus last attended? Are there any records which show how often the apparatus has been attended 12 months prior to the accident?</label>
            <div :class="{ 'error--text': !lastAttended }">{{lastAttended || 'Not specified'}}</div>
        </div>

        <div class="mb-2" v-if="apparatusVM">
            <label class="section-label">Is the apparatus visually defective?</label>
            <div>{{toYesNo(defective)}}</div>
        </div>

        <div class="mb-2" v-if="apparatusVM && defective">
            <label class="section-label">Please detail why you think this is</label>
            <div :class="{ 'error--text': !defectiveDetails }">{{defectiveDetails || 'Not specified'}}</div>
        </div>

        <v-row class="mt-2 mx-0" v-if="apparatusVM && defective">
            <v-alert type="info" dense>
                Take Video / Take Image of why you think this is.
            </v-alert>
        </v-row>

        <div class="mb-2" v-if="apparatusVM && defective">
            <label class="section-label">Is the defect still present?</label>
            <div>{{toYesNo(defectPresent)}}</div>
        </div>

        <div class="mb-2" v-if="apparatusVM">
            <label class="section-label">Does it look like any recent works by a third party may have been in the vicinity, that could have caused a defect to VM apparatus?</label>
            <div>{{toYesNo(thirdPartyRecentWorks)}}</div>
        </div>

        <div class="mb-2" v-if="apparatusVM">
            <label class="section-label">Are there any other hazards in the immediate vicinity?</label>
            <div>{{toYesNo(hazardsVicinity)}}</div>
        </div>

        <div class="mb-2" v-if="apparatusVM && hazardsVicinity">
            <label class="section-label">Please explain</label>
            <div :class="{ 'error--text': !hazardsVicinityDetails }">{{hazardsVicinityDetails || 'Not specified'}}</div>
        </div>

        <v-row class="mt-2 mx-0" v-if="apparatusVM && hazardsVicinity">
            <v-alert type="info" dense>
                Take Video / Image of this.
            </v-alert>
        </v-row>

        <div class="mb-2" v-if="apparatusVM">
            <label class="section-label">Is there evidence of recent work by VM which may have caused the alleged incident?</label>
            <div>{{toYesNo(evidenceVMWork)}}</div>
        </div>

        <div class="mb-2" v-if="apparatusVM && evidenceVMWork">
            <label class="section-label">Please detail why you think this is</label>
            <div :class="{ 'error--text': !evidenceVMWorkDetails }">{{evidenceVMWorkDetails || 'Not specified'}}</div>
        </div>

        <v-row class="mt-2 mx-0" v-if="apparatusVM && evidenceVMWork">
            <v-alert type="info" dense>
                Take Video / Image of why you think this is.
            </v-alert>
        </v-row>

        <div class="mb-2" v-if="apparatusVM && evidenceVMWork">
            <label class="section-label">Who is the contractor responsible for this work?</label>
            <div :class="{ 'error--text': !contractor }">{{contractor || 'Not specified'}}</div>
        </div>

        <div class="mb-2" v-if="apparatusVM">
            <label class="section-label">Do you have any observations based on the facts above which may help decide liability?</label>
            <div :class="{ 'error--text': !observations }">{{observations || 'Not specified'}}</div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        typeSystemName: String,
        date: String,                   // "DD/MM/YYYY"
        thirdPartyRecentWorks: Boolean,
        apparatusVM: Boolean,
        apparatusVMDetails: String,
        whenInstalled: String,
        lastAttended: String,
        defective: Boolean,
        defectiveDetails: String,
        defectPresent: Boolean,
        hazardsVicinity: Boolean,
        hazardsVicinityDetails: String,
        observations: String,
        contractor: String,
        evidenceVMWork: Boolean,
        evidenceVMWorkDetails: String,
    },

    methods: {
        toYesNo: function(b) {
            return b ? "Yes" : "No";
        },
    },
};
</script>
